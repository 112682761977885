import React from "react";
import Layout from "../../../components/Layout";

const DisclaimerPage = () => {
  const pageTitle = "Disclaimer Policy | Unschool";
  const pageDescription =
    "The Company shall disclaim all responsibility and owns no liability to Users for any outcome from the use of the Platform or any such services under the platform.";

  return (
    <Layout title={pageTitle} description={pageDescription}>
      <div className="policies-container">
        <h2 className="title">DISCLAIMER POLICY</h2>
        <p>
          We, Edupolis Technologies Private Limited, A Company incorporated
          under the Companies Act, 2013 having its office at H.No. 1-11-251/3,
          Ground Floor, Gurumurthy Colony Road, Behind Shoppers Stop, Begumpet,
          Hyderabad - 500016, Telangana, represented by its members, hereinafter
          referred to as the “Company” (where such expression shall, unless
          repugnant to the context thereof, be deemed to include its respective
          legal heirs, representatives, administrators, permitted successors and
          assigns).
        </p>
        <p>
          The Company will not be responsible for any damage suffered by users
          from use of the services on this site. This includes but is not
          limited to, loss of revenue/data resulting from delays,
          non-deliveries, missed deliveries, or service interruptions as may
          occur because of any act/omission of parties. This disclaimer of
          liability also applies to any damages or injury caused by any failure
          of performance, error, omission, interruption, deletion, defect, delay
          in operation or transmission, computer virus, communication line
          failure, theft or destruction or unauthorized access to, alteration
          of, or use of record, whether for breach of contract, tortuous
          behaviour, negligence, or under any other cause of action.
        </p>
        <p>
          The Company also makes it clear that it shall not be held liable for
          any damage/hurt/inconvenience caused to the User through the course of
          the provision of Services or as a result of the Users’ actions. The
          Company makes no representations or warranties as to the conduct of
          the Users. The Company takes no responsibility for any content that
          Users send or receive from other Users or ThirdParty posts through the
          Website or Platform. Any material downloaded or otherwise obtained
          through use of the service is accessed at the Users own discretion and
          risk.
        </p>
        <p>
          The services on the Platform are intended to be subject to
          availability, without any promises or guarantees on the same by the
          company, and while certain information available on the Platform is
          the property of the Company and the Company endeavours to keep the
          said information updated and accurate, the Company shall not make any
          representations or warranties of any kind, express or implied, about 2
          the completeness, accuracy, reliability, suitability or availability
          with respect to the Platform or the information, services, or related
          graphics contained on the Platform for any purpose. Any reliance you
          place on such information is therefore strictly at your own risk.
        </p>
        <p>
          The Company bears no responsibility whatsoever for any consequences
          arising from the use of the said Services by Users. The use of the
          Services in the Platform is the sole responsibility of the User (or
          legally authorised person on behalf of the User), and in case of any
          negligence on the part of the User is acting on the same shall not be
          construed as imposing any liability, direct or indirect, on the
          Company /Platform.
        </p>
        <p>
          The Company shall disclaim all responsibility and owns no liability to
          Users for any outcome (incidental, direct, indirect or otherwise) from
          the use of the Platform or any such services under the platform. In no
          event will the Company be liable for any loss or damage including
          without limitation, indirect or consequential loss or damage, or any
          loss or damage whatsoever arising from loss of data or profits arising
          out of, or in connection with, the use of the Platform.
        </p>
        <p>
          Through this Platform, You may be able to access the link of other
          Platforms which are not under the control of the Company or the
          Platform. We have no control over the nature, content and availability
          of those sites. The inclusion of any links does not necessarily imply
          a recommendation or endorse the views expressed within them. Every
          effort is made to keep the Platform up and running smoothly. However,
          the Company takes no responsibility for, and will not be liable for,
          the Platform being temporarily unavailable due to technical issues
          beyond our control.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL HAVE NO
          LIABILITY RELATED TO USER CONTENT ARISING UNDER INTELLECTUAL PROPERTY
          RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY OR OTHER LAWS. WEBSITE
          ALSO DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS,
          MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.
        </p>
        <p>
          THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL OR DATA DOWNLOADED
          OR OTHERWISE OBTAINED THROUGH THE PLATFORM IS DONE ENTIRELY AT HIS/HER
          OWN DISCRETION AND RISK AND HE/SHE WILL BE SOLELY RESPONSIBLE FOR ANY
          DAMAGE TO HIS/HER COMPUTER 3 SYSTEMS OR LOSS OF DATA THAT RESULTS FROM
          THE DOWNLOAD OF SUCH MATERIAL OR DATA. WE ARE NOT RESPONSIBLE FOR ANY
          TYPOGRAPHICAL ERROR LEADING. PLATFORM ACCEPTS NO LIABILITY FOR ANY
          ERRORS OR OMISSIONS, WITH RESPECT TO ANY INFORMATION PROVIDED TO YOU
          WHETHER ON BEHALF OF ITSELF OR THIRD PARTIES.
        </p>
        <p>
          THE ADVERTISEMENT THAT MAY BE AVAILABLE ON E-MAIL OR WEBSITE WITH
          RESPECT TO THE THIRD-PARTY WEBSITE OR THE PRODUCTS AND SERVICES ARE
          FOR INFORMATION PURPOSE ONLY.
        </p>
      </div>
    </Layout>
  );
};

export default DisclaimerPage;
